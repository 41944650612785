// extracted by mini-css-extract-plugin
export var btn_back = "HomeSlider-module--btn_back--9d8dd";
export var btn_nav = "HomeSlider-module--btn_nav--8c244";
export var btn_next = "HomeSlider-module--btn_next--560dc";
export var container = "HomeSlider-module--container--5ba9e";
export var content = "HomeSlider-module--content--5d2b9";
export var content_bg = "HomeSlider-module--content_bg--c92bc";
export var content_current = "HomeSlider-module--content_current--89ef3";
export var content_next = "HomeSlider-module--content_next--f139c";
export var content_prev = "HomeSlider-module--content_prev--c63c8";
export var section = "HomeSlider-module--section--c1d8d";
export var slide = "HomeSlider-module--slide--84f4f";
export var slide_current = "HomeSlider-module--slide_current--bfc34";
export var slide_next = "HomeSlider-module--slide_next--0233a";
export var slide_prev = "HomeSlider-module--slide_prev--6d356";
export var slider = "HomeSlider-module--slider--8131a";
export var title = "HomeSlider-module--title--ec114";
import React, { useEffect, useState, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { navigate } from "gatsby";
import * as styles from "./HomeService.module.scss";
import { mainURL, servicesURL } from "../../config/url";
import useIntersection from "../../utils/useIntersection";
import useIsMobileView from "../../hooks/useIsMobileView";
import ScrollReveal from "../layout/ScrollReveal";

const Services = [
  {
    name: "Website Design",
    desc: "WordPress, Headless CMS, Custom Design",
    url: servicesURL.webDesign,
    img: (
      <StaticImage
        src="../../images/home-website.jpg"
        alt="Web Design"
        className={styles["service_image"]}
      />
    ),
  },
  {
    name: "Ecommerce Setup",
    desc: "Payment Gateway, Billing System Integration",
    url: servicesURL.ecommerce,
    img: (
      <StaticImage
        src="../../images/home-ecommerce.jpg"
        alt="Ecommerce"
        className={styles["service_image"]}
      />
    ),
  },
  {
    name: "Software Development",
    desc: "Gated Content, API Integration, Cloud Solutions",
    url: servicesURL.software,
    img: (
      <StaticImage
        src="../../images/home-software.jpg"
        alt="Software"
        className={styles["service_image"]}
      />
    ),
  },
  {
    name: "Mobile Apps Development",
    desc: "UI/UX Designs, iOS and Android Development",
    url: servicesURL.apps,
    img: (
      <StaticImage
        src="../../images/home-app.jpg"
        alt="UI UX"
        className={styles["service_image"]}
      />
    ),
  },
];

const HomeService = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [hoverIndex, setHoverIndex] = useState();
  const timeoutRef = useRef(null);

  // const isBrowser = typeof window !== "undefined";
  const isMobileView = useIsMobileView();
  const ref = useRef();
  const isVisible = useIntersection(ref, "0px");

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  const onMouseOver = (index) => {
    setHoverIndex(index);
    setActiveIndex(-1);
    resetTimeout();
  };
  const onMouseLeave = () => {
    setHoverIndex(-1);
    setActiveIndex(0);
  };

  useEffect(() => {
    resetTimeout();
    if (isVisible && activeIndex > -1) {
      timeoutRef.current = setTimeout(
        () =>
          setActiveIndex((prevIndex) =>
            prevIndex === Services.length - 1 ? 0 : prevIndex + 1
          ),
        6000
      );
    }
    return () => resetTimeout();
  }, [activeIndex, isVisible]);

  const renderedItems = Services.map((item, idx) => {
    const active = idx === activeIndex ? "active" : "";
    const hover = idx === hoverIndex ? "hover" : "";

    return (
      <div
        className={classNames(
          styles["item"],
          active && styles["active"],
          hover && styles["hover"]
        )}
        role="button"
        tabIndex="0"
        key={idx}
      >
        <button
          type="button"
          to={item.url}
          className={styles["button"]}
          onMouseOver={() => onMouseOver(idx)}
          onMouseLeave={() => onMouseLeave(idx)}
          onFocus={() => onMouseOver(idx)}
          onClick={(e) => {
            e.preventDefault();
            navigate(mainURL.services);
          }}
        >
          <ScrollReveal effect="slide-left" delay={100}>
            <h3 className="h3">{item.name}</h3>
            <p>{item.desc}</p>
          </ScrollReveal>
        </button>
        <div className={styles["image"]}>
          <ScrollReveal>{item.img}</ScrollReveal>
        </div>
      </div>
    );
  });
  if (isMobileView)
    return (
      <section className="section wrapper">
        <div className="title">Services</div>
        <div ref={ref}>
          {Services.map((serviceEl, index) => {
            return (
              <div
                className={styles["item"]}
                role="button"
                tabIndex="0"
                key={index}
              >
                <button
                  type="button"
                  to={serviceEl.url}
                  className={styles["button"]}
                  onFocus={() => onMouseOver(index)}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(mainURL.services);
                  }}
                >
                  <ScrollReveal effect="slide-left" delay={100}>
                    <h3 className="h3">{serviceEl.name}</h3>
                    <p>{serviceEl.desc}</p>
                  </ScrollReveal>
                </button>
              </div>
            );
          })}
        </div>
      </section>
    );
  return (
    <section className="section wrapper">
      <div className="title">Services</div>
      <div ref={ref}>{renderedItems}</div>
    </section>
  );
};

export default HomeService;

import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./HomeProductSlider.module.scss";

import Mockup2 from "../../images/home-mockup-2.svg";
import Mockup3 from "../../images/home-mockup-3.svg";
import Mockup4 from "../../images/home-mockup-4.svg";
import Mockup5 from "../../images/home-mockup-5.svg";
import Mockup6 from "../../images/home-mockup-6.svg";
import Mockup7 from "../../images/home-mockup-7.svg";
import Mockup8 from "../../images/home-mockup-8.svg";
import Mockup9 from "../../images/home-mockup-9.svg";
import ScrollReveal from "../layout/ScrollReveal";

const HomeProductSlider = () => {
  return (
    <div className={styles.container}>
      <div className={styles.phoneFullImg}>
        <ScrollReveal effect="zoom-in" delay={50}>
          <StaticImage
            src="../../images/home-app-phone.png"
            alt="Addpointment Mockup"
          />
        </ScrollReveal>
      </div>
      <div className={styles.mockupList}>
        <div className={styles["col2"]}>
          <ScrollReveal effect="zoom-in" delay={230}>
            <div className={styles["bg_dark"]}>
              <p>
                Better manage your resources with improved appointments
                traceability
              </p>
            </div>
          </ScrollReveal>
          <ScrollReveal effect="zoom-in" delay={215}>
            <img src={Mockup2} alt="mockup"></img>
          </ScrollReveal>
        </div>
        <div className={styles["col2"]}>
          <ScrollReveal effect="zoom-in" delay={200}>
            <img src={Mockup3} alt="mockup"></img>
          </ScrollReveal>
          <ScrollReveal effect="zoom-in" delay={175}>
            <div className={styles["bg_dark"]}>
              <p>
                Comes with various booking solutions fit for your business's
                unique needs
              </p>
            </div>
          </ScrollReveal>
        </div>
        <div className={styles["col2"]}>
          <ScrollReveal effect="zoom-in" delay={100}>
            <img src={Mockup4} alt="mockup"></img>
          </ScrollReveal>

          <ScrollReveal effect="zoom-in" delay={150}>
            <img src={Mockup5} alt="mockup"></img>
          </ScrollReveal>
        </div>
        <div className={styles["col2"]}>
          <></>
        </div>
        <div className={styles["col2"]}>
          <ScrollReveal effect="zoom-in" delay={150}>
            <div>
              <p>
                A hassle-free, comprehensive online booking management system
              </p>
            </div>
          </ScrollReveal>
          <ScrollReveal effect="zoom-in" delay={100}>
            <img src={Mockup6} alt="mockup"></img>
          </ScrollReveal>
        </div>
        <div className={styles["col2"]}>
          <ScrollReveal effect="zoom-in" delay={200}>
            <img src={Mockup7} alt="mockup"></img>
          </ScrollReveal>
          <ScrollReveal effect="zoom-in" delay={175}>
            <img src={Mockup8} alt="mockup"></img>
          </ScrollReveal>
        </div>
        <div className={styles["col2"]}>
          <ScrollReveal effect="zoom-in" delay={215}>
            <img src={Mockup9} alt="mockup"></img>
          </ScrollReveal>
          <ScrollReveal effect="zoom-in" delay={230}>
            <div className={styles["bg_dark"]}>
              <p>
                Improve your customers' experience with reduced waiting time
              </p>
            </div>
          </ScrollReveal>
        </div>
      </div>
    </div>
  );
};
export default HomeProductSlider;

import { useState, useEffect } from "react";

const isBrowser = typeof window !== "undefined";

function getWindowDimensions() {
  const { innerWidth: windowWidth, innerHeight: windowHeight } =
    isBrowser && window;
  return {
    windowWidth,
    windowHeight,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    if (isBrowser) {
      window.addEventListener("resize", handleResize);
    }
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

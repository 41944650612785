import * as React from "react";
import { Link } from "gatsby";
// import * as styles from "./index.module.scss";
import PageLayout from "../components/layout/PageLayout";
import Masthead from "../components/layout/Masthead";
import Seo from "../components/layout/SEO";
import Intro from "../components/layout/Intro";
import { Col, Row } from "../components/layout/Grid";
import ScrollReveal from "../components/layout/ScrollReveal";
import HomeService from "../components/home/HomeService";
import HomeSlider from "../components/home/HomeSlider";
import HomeProductSlider from "../components/home/HomeProductSlider";
// import HomeProductSlider2 from "../components/home/HomeProductSlider2";

import simpliciticAnim from "../assets/json/simplicitic.json";
import { mainURL } from "../config/url";

// markup
const IndexPage = () => {
  return (
    <PageLayout includeCTA anim={simpliciticAnim}>
      <Seo title="Simplicitic - Digital Agency &amp; Software Company in Malaysia" />
      <Masthead
        title={
          <>
            We Simplify <span>Digital Experience</span>
          </>
        }
        desc="A digital agency and software company based in Subang Jaya,
            Malaysia."
      />
      <Intro>
        <div className="title">About</div>
        <Row>
          <Col width="40%">
            <ScrollReveal effect="slide-right" delay={50}>
              <h2 className="h2">Website Design & App Development</h2>
            </ScrollReveal>
          </Col>
          <Col width="50%">
            <ScrollReveal effect="slide-left" delay={50}>
              <p className="h4">
                We create simple solutions so anyone can understand technology.
              </p>
              <Link to={mainURL.about} className="btn">
                About Us
              </Link>
            </ScrollReveal>
          </Col>
        </Row>
      </Intro>
      <HomeSlider />
      <section>
        <Row className="section wrapper">
          <div className="title">Product</div>
          <Col>
            <ScrollReveal effect="slide-right">
              <h2 className="h2">
                A booking app that reduces customers' waiting time
              </h2>
            </ScrollReveal>
          </Col>
          <Col width="50%">
            <ScrollReveal effect="slide-left">
              <p className="h4">
                Designed for service providers in solving challenges of juggling
                schedule maintenance and customers' bookings organisation
              </p>
              <Link to={mainURL.product} className="btn">
                View Product
              </Link>
            </ScrollReveal>
          </Col>
        </Row>
        <Row>
          <Col width="100%">
            <HomeProductSlider />
          </Col>
        </Row>
      </section>
      <HomeService />
    </PageLayout>
  );
};

export default IndexPage;
